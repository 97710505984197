import { ReactNode, useMemo } from 'react';

import { useFeatureTogglesQuery } from '@query';

import { useAuth } from '@shared/common/providers/AuthProvider';

import I18nInfoTooltip from '@store:web/components/common/I18nInfoTooltip/I18nInfoTooltip';
import { SEARCH_PARAM_MODAL } from '@store:web/utils/hooks/useSearchParamModal';

import ProfileLayoutSidebarNavLink from './ProfileLayoutSidebarNavLink';
import ProfileLayoutSidebarSubNav from './ProfileLayoutSidebarSubNav';

export interface ProfileNavItemWithLink {
  hidden?: boolean;
  hiddenInMenu?: boolean;
  id: string;
  label: ReactNode;
  href: string;
  target?: string;
  elementId?: string;
}

interface ProfileNavItem {
  hidden?: boolean;
  hiddenInMenu?: boolean;
  id: string;
  label: ReactNode;
  items: ProfileNavItemWithLink[];
  hasBottomDivider?: boolean;
  elementId?: string;
}

export type Items = ProfileNavItemWithLink | ProfileNavItem;

export const useProfileNavItems = (): Items[] => {
  const { user } = useAuth();
  const { data: featureToggle } = useFeatureTogglesQuery();

  return useMemo(() => {
    const developerNavItems: Items[] = user?.attributes.creatorId
      ? [
          {
            hiddenInMenu: true,
            id: 'developer',
            label: <>Developers</>,
            items: [
              {
                id: 'developer-info',
                label: 'Developer Settings',
                href: '/profile/developer-info',
              },
              {
                id: 'developer-dapps',
                label: 'My Applications',
                href: '/profile/developer-dapps',
              },
              {
                id: 'campaign-review',
                label: 'Campaign Review',
                href: '/profile/developer-campaign',
                hidden: !featureToggle?.campaignReview,
              },
            ].filter((item) => !item.hidden),
            hasBottomDivider: !featureToggle?.developerCampaigns,
          },
          ...(featureToggle?.developerCampaigns
            ? [
                {
                  id: 'campaigns',
                  label: 'Campaigns',
                  items: [
                    {
                      id: 'campaign-dashboard',
                      label: 'Campaign Dashboard',
                      href: '',
                    },
                    {
                      id: 'launch-campaign',
                      label: 'Launch Campaign',
                      href: 'https://forms.gle/s9DNEmCk6JUgS7US6',
                      target: '_blank',
                    },
                  ],
                  hasBottomDivider: true,
                },
              ]
            : []),
        ]
      : [
          {
            hiddenInMenu: true,
            id: 'developer',
            label: (
              <>
                Developers
                <span className="tag ml-auto">Apply</span>
              </>
            ),
            href: '/profile/become-creator',
          },
        ];

    return (
      [
        {
          id: 'profile',
          label: 'Profile Overview',
          href: '/profile/settings',
        },
        {
          id: 'magic-membership',
          hidden: !featureToggle?.membership,
          label: (
            <span className="inline-flex items-baseline gap-2 justify-between w-full whitespace-nowrap">
              Magic Memberships
              <span className="tag border border-green-600 bg-green-50 text-green-600">Active</span>
            </span>
          ),
          items: [
            {
              id: 'magic-membership-dashboard',
              label: 'Dashboard',
              href: '/profile/membership/dashboard',
            },
            {
              id: 'magic-membership-plans',
              label: 'Membership Plans',
              href: '/profile/membership/plans',
            },
          ],
        },
        {
          id: 'karma',
          label: 'Karma & Rewards',
          items: [
            {
              id: 'karma-dashboard',
              label: 'Dashboard',
              href: '/profile/karma',
            },
            {
              id: 'karma-summary',
              label: 'Summary',
              hidden: !featureToggle?.membership,
              href: '/profile/membership/summary',
            },
            {
              id: 'karma-leaderboard',
              label: 'Leaderboard',
              href: `/profile/karma?modal=${SEARCH_PARAM_MODAL.KARMA_LEADERBOARD}`,
            },
            {
              id: 'karma-history',
              label: 'Karma History',
              href: `/profile/karma?modal=${SEARCH_PARAM_MODAL.KARMA_HISTORY}`,
            },
          ],
        },
        {
          id: 'validation',
          elementId: 'profile-nav-vote-link',
          label: (
            <span className="inline-flex items-baseline gap-2 justify-between w-full whitespace-nowrap">
              Vote on Projects
              <span className="tag ml-auto">Vote & Earn</span>
            </span>
          ),
          href: '/profile/validation-tasks',
        },
        ...(developerNavItems as ProfileNavItem[]),
        {
          id: 'raf',
          elementId: 'profile-nav-raf-link',
          label: (
            <>
              Invite Friends{' '}
              <I18nInfoTooltip
                title="clientArea_menuItem_raf_title"
                content="clientArea_menuItem_raf_description"
              />
            </>
          ),
          href: '/refer-a-friend',
        },
        {
          id: 'terms',
          label: 'Terms & Conditions',
          href: 'https://docs.magic.store/documents/legal-documents/magic-store-terms-and-conditions',
          target: '_blank',
        },
      ] as Items[]
    ).filter((item) => !item.hidden);
  }, [user, featureToggle?.developerCampaigns, featureToggle?.membership]);
};

const ProfileLayoutSidebarNav = () => {
  const profileNavItems = useProfileNavItems();

  return (
    <nav className="flex flex-col gap-1 w-full">
      {profileNavItems.map((item) => {
        if ('items' in item) {
          return <ProfileLayoutSidebarSubNav key={item.id} {...item} />;
        }

        return (
          <ProfileLayoutSidebarNavLink
            key={item.id}
            href={item.href}
            target={item.target}
            id={item.elementId}
          >
            {item.label}
          </ProfileLayoutSidebarNavLink>
        );
      })}
    </nav>
  );
};

export default ProfileLayoutSidebarNav;
